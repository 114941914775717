// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export interface Environment {
  production: boolean;
  geminiApiKey: string;
  openaiApiKey: string;
  deepseekApiKey: string;
  claudeApiKey: string;
  llamaApiKey: string;
  mistralApiKey: string;
}

export const environment: Environment = {
  production: true,
  geminiApiKey: 'AIzaSyBrjgg8u5HHGFYwtmXxYT7GOHz8Oabaq74', //Gemini API
  openaiApiKey: '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', //OpenAI API
  deepseekApiKey: 'sk-8e7904c8457d420db2a11a72f714cc7e', //Deepseek API
  claudeApiKey: '', //Claude API
  llamaApiKey: '', //Llama API
  mistralApiKey: '' //Mistral API
};
